import React from 'react'
import EducationItem from './educationItem/educationItem'

class EducationGroup extends React.Component {

    render() {
        return (
            <section className="ExperienceGroup">
                <h2>Education</h2>
                <ul>
                    <EducationItem
                        degree="MS"
                        program="Human-Computer Interaction"
                        school="DePaul University"
                        graduationYear="2018" />
                    <EducationItem
                        degree="BBA"
                        program="Entrepreneurship"
                        school="University of Cincinnati"
                        graduationYear="2014" />
                </ul>
            </section>
        )
    }

}

export default EducationGroup