import React from 'react'
import "./experienceItem.css"

const ExperienceItem = (props) => (
    <li className="ExperienceItem">
        <h3 className="ExperienceHead"><span className="jobTitle">{props.jobTitle}: </span><span className="company">{props.company}</span></h3>
        <h4 className="ExperienceSubhead">
            <span className="employmentDates">{props.employmentDates}</span>
        </h4>
        <p className="jobDescription">{props.jobDescription}</p>
    </li>
)

export default ExperienceItem

