import React from "react"
import { OutboundLink } from 'gatsby-plugin-google-analytics'

import Layout from "../components/layout"
import SEO from "../components/seo"
import ExperienceGroup from "../components/experienceGroup/experienceGroup"
import EducationGroup from "../components/educationGroup/educationGroup"

const AboutPage = () => (
  <Layout>
    <SEO title="About Me" />
    <div>
      <div className="HeroGroup AboutHeroGroup">
        <div className="AboutText">
          <h1>Hello World!</h1>
          <p>I’m a UX Professional dedicated to solving tomorrow’s problems, with a Human approach, because most problems are people problems...</p>
          <p>Fun Fact: I’m a self-procclaimed Rum connoisseur</p>
        </div>
      </div>
      <div className="AboutTextMobile">
        <h1>Hello World!</h1>
        <p>I’m a UX Professional dedicated to solving tomorrow’s problems, with a Human approach, because most problems are people problems...</p>
        <p>Fun Fact: I’m a self-procclaimed Rum connoisseur</p>
      </div>
      <div className="Resume">
        <ExperienceGroup />
        <EducationGroup />
        <div className="ctaRow">
          <OutboundLink href="https://drive.google.com/file/d/1WjkKODlmE1DztyRuByMhYpZkM4AtZkmR/view?usp=sharing" target="_blank" rel="noopener noreferrer">Download Resume</OutboundLink>
        </div>
      </div>
    </div>
  </Layout>
)

export default AboutPage
