import React from 'react'
import ExperienceItem from './experienceItem/experienceItem'

class ExperienceGroup extends React.Component {

    state = {
        posts: []
    }

    render() {
        return (
            
            <section className="ExperienceGroup">
                <h2>Experience</h2>
                <ul>
                    <ExperienceItem 
                        jobTitle="Senior Interaction Designer"
                        company="Rise Interactive"
                        employmentDates="Nov 2018 - Current"
                        jobDescription="Responsible for the consistency and usability of a product. Collaborates closely with the Product Management team to discover the best path for the product, delivers prototypes and wireframes, works with technical leads to determine feasibility and assists in visual design and design consistency through the development process."
                        />
                    <ExperienceItem 
                        jobTitle="Freelance User Experience Designer"
                        company="Ryan B. Designs"
                        employmentDates="Jan 2013 - Current"
                        jobDescription="Propose informational or creative mobile app, website and blog designs to clients, develop the site according to their specifications using wireframes or content management system (CMS) such as Wordpress, and assist with site updates and maintenance"
                        />
                    <ExperienceItem 
                        jobTitle="User Experience Architect"
                        company="Blue Chip Marketing Worldwide"
                        employmentDates="Feb 2018 - Nov 2018"
                        jobDescription="Work collaboratively with the digital project management team, account teams, digital creative, analytics, social teams and strategy to present best in class user experience approaches while keeping client interests a top priority"
                        />
                    <ExperienceItem 
                        jobTitle="Digital Project Manager"
                        company="Blue Chip Marketing Worldwide"
                        employmentDates="Jan 2016 - Feb 2018"
                        jobDescription="Led a team of UX Designers, Web Designers, Copywriters, Developers and Content Strategists in the execution of digital campaigns for Health & Wellness Clients"
                        />
                </ul>
            </section>
        )
    }

}

export default ExperienceGroup