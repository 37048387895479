import React from 'react'
import "./educationItem.css"

const EducationItem = (props) => (
    <li className="EducationItem">
        <h3 className="degreeHead"><span className="degree">{props.degree} </span><span className="program">{props.program}</span></h3>
        <h4>
            <span className="school">{props.school} </span><span className="graduationYear">{props.graduationYear}</span>
        </h4>
    </li>
)

export default EducationItem

